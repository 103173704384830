import gsap from 'gsap';
import { CustomEase } from 'gsap/CustomEase';

gsap.registerPlugin(CustomEase);

export const gsapNavbar = () => {
  // gsap.fromTo(
  //   '.navbar',
  //   {
  //     y: 100,
  //   },
  //   {
  //     opacity: 1,
  //     y: 0,
  //     ease: 'ease',
  //     delay: 1.5,
  //     duration: 1,
  //   },
  // );
};
